import axios from 'axios'
import Api from '@hypefactors/shared/js/services/Api'

/**
 * Creates a new Axios instance with the Hypenews base url
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const ApiInstance = new Api(axiosInstance)

export default ApiInstance
