import Vue from 'vue'

import Api from '@/services/Api'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Trans } from '@/services/TranslationService'
import { hasAccess } from '@/shared/auth'

import ApiStatusChecker from '@hypefactors/shared/js/services/ApiStatusChecker'
import { displayRequestError, handleError } from '@hypefactors/shared/js/utils/handleErrors'
import { $redirectTo, getUrlToDomain, storagePath } from '@hypefactors/shared/js/utils/index'
import { PusherInstance } from '@hypefactors/shared/js/services/SocketService'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

Vue.prototype.$safeGet = _get
Vue.prototype.$bus = new Vue()
Vue.prototype.$isEmpty = _isEmpty
Vue.prototype.$asset = storagePath
Vue.prototype.$pusher = PusherInstance
Vue.prototype.$getUrl = getUrlToDomain
Vue.prototype.$redirectTo = $redirectTo
Vue.prototype.$handleError = handleError
Vue.prototype.$ENV = process.env.VUE_APP_ENV
Vue.prototype.$displayRequestError = displayRequestError
Vue.prototype.$locationService = LocationService
Vue.prototype.$isDevMode = process.env.VUE_APP_ENV === 'development'
Vue.prototype.$apiStatusChecker = ApiStatusChecker

Vue.prototype.$api = Api
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans) // bind so we dont loose `this`
Vue.prototype.$TranslationService = Trans
Vue.prototype.$hasAccess = hasAccess
