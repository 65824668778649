<template>
  <router-view />
</template>

<script>
import store from '@/store'
import { handleError } from '@hypefactors/shared/js/utils'
import { errorLoggingService } from '@hypefactors/shared/js/services/errorLoggingService'

/**
 * Empty wrapper component in the Route config to fetch data before the rest of the routes are rendered
 * @module RouterWrapperPage
 */
export default {
  name: 'RouterWrapperPage',

  async beforeRouteEnter (to, from, next) {
    if (store.getters.isLoggedIn) {
      try {
        await store.dispatch('fetchCurrentUser')
      } catch (err) {
        handleError(err)
      }
    }
    next(vm => {
      if (vm.currentUser) {
        errorLoggingService.identify(vm.currentUser)
        vm.$ma.setUsername(vm.currentUser.id)
      }
      vm.stopLoad()
    })
  }
}
</script>
