<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.create_story_dialog.title')"
    class="CreateStoryDialog"
    width="50%"
  >
    <basic-select
      v-model="chosenBrand"
      :values="brands"
      :placeholder="$t('components.create_story_dialog.choose_brand')"
      key-prop="id"
      value-key="id"
      label-prop="name"
      class="m-b-m"
    />
    <basic-select
      #default="{ item: newsroom }"
      :disabled="!chosenBrand"
      :values="chosenBrand ? chosenBrand.newsrooms : []"
      :placeholder="$t('components.create_story_dialog.choose_newsroom')"
      key-prop="id"
      value-prop="id"
      label-prop="name"
      first-default
      @change="handleNewsroomSelection"
    >
      {{ newsroom.name }} - {{ newsroom.country.name }}
    </basic-select>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { goToStoryCreatePage } from '@/shared/splashScreenHelpers'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Generates the dialog to pick a newsroom to which to redirect to.
 * @module CreateStoryDialog
 */
export default {
  name: 'CreateStoryDialog',

  data () {
    return {
      chosenBrand: null
    }
  },

  computed: {
    ...mapState(['createStoryModalVisible']),
    ...mapGetters(['brands']),

    isVisible: generateComputedSetterWithMethod('createStoryModalVisible', 'TOGGLE_CREATE_STORY_MODAL')
  },

  methods: {
    ...mapMutations(['TOGGLE_CREATE_STORY_MODAL']),
    goToStoryCreatePage: goToStoryCreatePage,
    handleNewsroomSelection (newsroom) {
      this.goToStoryCreatePage(this.chosenBrand.id, newsroom)
    }
  }
}
</script>
