import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueClipboards from 'vue-clipboards'
import YouTubeEmbed from 'vue-youtube-embed'
import VuelidateErrorExtractor from 'vuelidate-error-extractor'
import SocialSharing from 'vue-social-sharing'
import VueProgressBar from 'vue-progressbar'
import VueObserveVisibility from 'vue-observe-visibility'

import { sync } from 'vuex-router-sync'

import FormField from '@hypefactors/shared/js/components/forms/FormField'

import store from '@/store'
import router from '@/router'

import { analyticsPlugin } from './analytics'

sync(store, router)

// eslint-disable-next-line no-unused-vars
Vue.use(Vuelidate)
Vue.use(VuelidateErrorExtractor, {
  name: 'FormField',
  i18n: 'validations',
  template: FormField
})
Vue.use(SocialSharing)
Vue.use(VueProgressBar, {
  color: '#c8471b',
  failedColor: 'black',
  height: '2px'
})

// simulate plugin attachment
Vue.prototype.$multianalytics = Vue.prototype.$ma = Vue.ma = analyticsPlugin

Vue.use(VueClipboards)
Vue.use(YouTubeEmbed, { global: false })
Vue.use(VueObserveVisibility)
