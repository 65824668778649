import _get from 'lodash/get'
import Api from '@/services/Api'
import { i18n } from '@/bootstrap/i18n'
import { CrossAuth } from '@/services/CrossStorage'
import { handleError } from '@hypefactors/shared/js/utils'
import { TranslationService } from '@hypefactors/shared/js/services/TranslationService'

class HypenewsTranslationService extends TranslationService {
  /**
   * Returns a route object with i18n setup
   * @param {Object} to - route object to construct
   */
  i18nRoute (to) {
    const toLang = _get(to, 'params.lang', this.currentLanguage)
    to.params = { ...to.params, lang: toLang }
    return to
  }
}

const Trans = new HypenewsTranslationService({
  Api,
  i18n,
  CrossAuth,
  handleError
})

export { Trans }
