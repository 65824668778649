import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

const state = {
  /**
   * @type {Object.<string, Newsroom[]>} Object property is the brand_id
   */
  newsroomsHashMap: {},
  /**
   * @type {Newsroom[]}
   */
  newsroomsFlat: []
}

const getters = {
  /**
   * Returns a Hashmap if newsrooms by the brand if
   * @param state
   * @returns {Object<string, Newsroom[]>}
   */
  newsroomsHashMap (state) {
    return state.newsroomsHashMap
  },
  hasNewsrooms (state) {
    return !!state.newsroomsFlat.length
  },
  /**
   * Returns the first available newsroom for a logged in user
   * @returns {Newsroom}
   */
  firstNewsroom (state) {
    return state.newsroomsFlat[0]
  },
  newsroomsFlat (state) {
    return state.newsroomsFlat
  },
  /**
   * Checks if the currently logged user owns a newsroom with such a slug
   * @param {string} newsroomSlug
   * @returns {boolean}
   */
  userOwnsNewsroom: (state, getters) => newsroomSlug => {
    return !!getters.newsroomsFlat.find(n => n.slug === newsroomSlug)
  }
}

const mutations = {
  SET_NEWSROOMS_FLAT: createSetter('newsroomsFlat'),
  SET_NEWSROOMS_HASH_MAP: createSetter('newsroomsHashMap')
}

const actions = {
  storeNewsroomsFromBrands ({ commit }, brands) {
    const newsroomsMap = brands
      .reduce((all, brand) => {
        all[brand.id] = brand.newsrooms.map((newsroom) => ({
          ...newsroom,
          brandId: brand.id,
          brandSlug: brand.slug
        }))
        return all
      }, {})

    const flatNewsrooms = Object.values(newsroomsMap).flat(1)

    commit('SET_NEWSROOMS_HASH_MAP', newsroomsMap)
    commit('SET_NEWSROOMS_FLAT', flatNewsrooms)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
