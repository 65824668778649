<template>
  <router-link
    :to="$i18nRoute({ name: 'dashboard' })"
    class="JournalistDashboardLink NavLink"
  >
    <i class="hf hf-dashboard hf-fw hf-lg" />
    {{ $t('components.top_nav.dashboard') }}
  </router-link>
</template>

<script>
/**
 * @module JournalistDashboardLink
 */
export default {
  name: 'JournalistDashboardLink'
}
</script>
