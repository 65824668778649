import store from '@/store'
import { Trans } from '@/services/TranslationService'

const ownsNewsroom = (slug) => store.getters['personalNewsrooms/userOwnsNewsroom'](slug)

export async function allowOnlyNewsroomOwners (to, from, next) {
  // go to English if we came from a page refresh
  const goAway = !from.name ? '/en' : false
  // go away of not logged in
  if (!store.getters.isLoggedIn) return next(goAway)
  // if there are no brands or the current brand does not have such a newsroom, re-fetch the brands just in case.
  if (store.state.auth.brands.length === 0 || !ownsNewsroom(to.params.newsroomSlug)) {
    await store.dispatch('fetchUserBrands')
  }
  if (!ownsNewsroom(to.params.newsroomSlug)) return next(goAway)

  next()
}

/**
 * Redirects to the Home page with a language param
 * @param to
 * @param next
 * @return {Promise<*>}
 */
export async function redirectToHomeWithProperLanguage (to, next) {
  const lang = await Trans.getGloballySetLanguage()
  next({
    name: store.getters.isLoggedIn ? 'home' : 'landing-free-newsroom3',
    params: { lang },
    query: to.query
  })
}
