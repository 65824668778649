import app from './app'
import auth from './auth'
import dashboard from './dashboard'
import embedded from './embedded'
import stories from './stories'
import personalNewsrooms from './personalNewsrooms'
import updateChecker from './updateChecker'
import newsroomSocialHandles from './newsroomSocialHandles'

export default {
  app,
  auth,
  dashboard,
  embedded,
  stories,
  personalNewsrooms,
  updateChecker,
  newsroomSocialHandles
}
