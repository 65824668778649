import Wrapper from '@/pages/Wrapper'
import RouterWrapperPage from '@/pages/RouterWrapperPage'
import { Trans } from '@/services/TranslationService'
import { allowOnlyNewsroomOwners, redirectToHomeWithProperLanguage } from './routerGuards'

function load (component) {
  // '@' is aliased to src/components
  return () => import(/* webpackChunkName: "[request]" */ `@/pages/${component}.vue`)
}

const languageSpecificPages = [
  {
    path: '404',
    component: load('Error404'),
    name: 'page_not_found',
    meta: {
      seo: {
        section: 'pages.page_not_found.meta.section'
      }
    }
  },
  {
    path: '',
    name: 'home',
    component: load('Stories'),
    beforeEnter (to, from, next) {
      if (!to.params.lang) {
        return redirectToHomeWithProperLanguage(to, next)
      }
      next()
    },
    meta: {
      seo: {
        section: 'pages.stories.meta.section',
        desc: 'pages.stories.meta.desc'
      }
    }
  },
  /**
   * @deprecated
   * Old stories path.
   * Kept as a fallback.
   */
  {
    path: 'stories',
    name: 'stories',
    redirect: { name: 'home' }
  },
  {
    path: 'dashboard',
    component: load('dashboard/Dashboard'),
    meta: {
      auth: ['reader']
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        redirect: { name: 'dashboard.suggested_stories' }
      },
      {
        path: 'alerts',
        name: 'dashboard.alerts',
        component: load('dashboard/DashboardAlerts'),
        meta: {
          seo: {
            title: 'pages.dashboard.alerts',
            section: 'pages.dashboard.meta.section'
          }
        }
      },
      {
        path: 'suggested-stories',
        name: 'dashboard.suggested_stories',
        component: load('dashboard/DashboardSuggestedStories'),
        meta: {
          seo: {
            title: 'pages.dashboard.suggested_stories',
            section: 'pages.dashboard.meta.section'
          }
        }
      },
      {
        path: 'suggested-companies',
        name: 'dashboard.suggested_newsrooms',
        component: load('dashboard/DashboardSuggestedNewsrooms'),
        meta: {
          seo: {
            title: 'pages.dashboard.suggested_companies',
            section: 'pages.dashboard.meta.section'
          }
        }
      }
    ]
  },
  {
    path: 'activate/:activationToken',
    component: load('UserActivation'),
    name: 'user-activation',
    props: true
  },
  {
    path: 'password-reset/:usersEncodedId/:activationCode',
    component: load('UserPasswordReset'),
    name: 'password-reset',
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: 'terms',
    component: load('Terms'),
    name: 'terms',
    meta: {
      seo: {
        title: '',
        section: 'pages.terms.meta.section'
      }
    }
  },
  {
    path: 'register-newsroom',
    name: 'NewsroomRegister',
    component: load('registrations/FreeNewsroomLanding2'),
    props: {
      transPrefix: 'free_newsroom3',
      gaEvent: 'Free Newsroom 3'
    },
    meta: {
      auth: false,
      seo: {
        section: 'pages.register.meta.section'
      },
      scrollToTop: true
    }
  },
  {
    path: 'register-journalist',
    component: load('registrations/JournalistRegister'),
    name: 'JournalistRegister',
    meta: {
      auth: false,
      seo: {
        section: 'pages.register_journalist.meta.section'
      }
    }
  },
  {
    path: 'landing-pages/free-newsroom2',
    name: 'landing-free-newsroom2',
    component: load('registrations/FreeNewsroomLanding2'),
    props: {
      transPrefix: 'free_newsroom2',
      gaEvent: 'Free Newsroom 2'
    }
  },
  {
    path: 'landing-pages/free-newsroom3',
    name: 'landing-free-newsroom3',
    component: load('registrations/FreeNewsroomLanding2'),
    props: {
      transPrefix: 'free_newsroom3',
      gaEvent: 'Free Newsroom 3'
    }
  },
  {
    path: 'login',
    name: 'login',
    component: load('auth/Login'),
    meta: {
      auth: false
    }
  },
  {
    path: 'forgotten-password',
    name: 'forgotten-password',
    component: load('auth/ForgottenPassword'),
    meta: {
      auth: false
    }
  }
]

export default [
  {
    // an empty component with pre-fetching logic.
    path: '',
    component: RouterWrapperPage,
    children: [
      {
        path: '/embedded',
        component: load('embedded/Index'),
        children: [
          {
            path: 'newsroom/:newsroomSlug/story/:storySlug',
            component: load('embedded/EmbeddedStory'),
            name: 'embedded.story',
            props: true
          },
          {
            path: 'newsroom/:newsroomSlug',
            component: load('embedded/EmbeddedNewsroom'),
            props: true,
            children: [
              {
                path: '',
                redirect: { name: 'embedded.newsroom.stories' }
              },
              {
                path: 'home',
                component: load('newsroom/NewsroomAbout'),
                name: 'embedded.newsroom.about'
              },
              {
                path: 'stories',
                component: load('embedded/EmbeddedNewsroomStories'),
                name: 'embedded.newsroom.stories'
              },
              {
                path: 'assets',
                component: load('newsroom/NewsroomAssets'),
                name: 'embedded.newsroom.assets'
              }
            ]
          }
        ]
      },
      {
        path: '/story_press_releases/:storySlug',
        component: load('PressRelease'),
        props: true,
        name: 'pr_story'
      },
      {
        name: 'firstNewsroomPageRedirect',
        path: '_redirect/first-newsroom-edit',
        component: load('_redirect/FirstNewsroomEdit')
      },
      {
        path: `/:lang(${Trans.supportedLanguages.join('|')})`, // this is done so it doesn't match the newsrooms page, which is /newsroomSlug
        component: Wrapper,
        children: [
          ...languageSpecificPages,
          {
            path: '*',
            redirect: { name: 'page_not_found' }
          }
        ]
      },
      {
        path: '/:newsroomSlug/edit',
        component: load('newsroomEdit/NewsroomEdit'),
        props: true,
        beforeEnter: allowOnlyNewsroomOwners,
        children: [
          {
            path: '',
            name: 'newsroom.edit',
            component: load('newsroomEdit/NewsroomEditStories')
          },
          {
            path: 'home',
            component: load('newsroomEdit/NewsroomEditAbout'),
            name: 'newsroom.edit.about'
          },
          {
            path: 'assets',
            component: load('newsroomEdit/NewsroomEditAssets'),
            name: 'newsroom.edit.assets'
          }
        ]
      },
      {
        path: '/:newsroomSlug',
        component: load('newsroom/Newsroom'),
        props: true,
        meta: {
          seo: {
            title: '',
            section: 'pages.company.meta.section'
          }
        },
        children: [
          {
            path: '',
            component: load('newsroom/NewsroomStories'),
            name: 'newsroom.stories',
            meta: {
              seo: {
                title: '',
                section: 'pages.company_stories.meta.section'
              }
            }
          },
          {
            path: 'home',
            component: load('newsroom/NewsroomAbout'),
            name: 'newsroom.about',
            meta: {
              seo: {
                title: '',
                section: 'pages.company.meta.section'
              }
            }
          },
          {
            path: 'assets',
            component: load('newsroom/NewsroomAssets'),
            name: 'newsroom.assets',
            meta: {
              seo: {
                title: '',
                section: 'pages.company_assets.meta.section'
              }
            }
          }
        ]
      },
      {
        path: '/:newsroomSlug/:storySlug',
        component: load('StoryFull'),
        name: 'story',
        meta: {
          seo: {
            title: '',
            section: 'pages.stories.meta.section'
          }
        }
      },
      /**
       * Catches all calls to / path.
       * Used to redirect to a proper supported language.
       */
      {
        path: '*',
        beforeEnter (to, from, next) {
          return redirectToHomeWithProperLanguage(to, next)
        }
      }
    ]
  }
]
