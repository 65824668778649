import 'intersection-observer'

import Vue from 'vue'

import store from '@/store'
import router from '@/router'
import { i18n } from '@/bootstrap/i18n'
import { Trans } from '@/services/TranslationService'

import Root from '@/pages/Root.vue'

import '@/bootstrap/setupSharedDependencies'
import '@/bootstrap/prototypeProperties'
import '@/bootstrap/globalComponents'
import '@/bootstrap/initElementUI'
import '@/bootstrap/bootstrap' // TODO: Attempt to remove this at some point
import '@/bootstrap/directives'
import '@/bootstrap/plugins'
import '@/bootstrap/globalMixin'
import '@/bootstrap/ApiInterceptors' // TODO: Attempt to remove this at some point
import '@/router/globalRouterGuards' // TODO: Attempt to remove this at some point

Vue.config.productionTip = false

store.dispatch('updateChecker/init') // TODO: Create a service instead

shouldSyncGlobalAuth()
  .finally(async () => {
    await Trans.changeLanguage(await Trans.getGloballySetLanguage())

    const app = new Vue({
      el: '#hypenews',
      store,
      router,
      i18n,
      render: h => h(Root)
    })

    if (window.Cypress) {
      window.app = app
    }
  })

function shouldSyncGlobalAuth () {
  return (!window.Cypress || window.location.search.includes('syncDownGlobalAuth'))
    ? store.dispatch('syncDownGlobalAuth')
    : Promise.resolve()
}
