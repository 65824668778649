<template>
  <a
    :href="storiesCreateUrl"
    class="CreateStoryButton"
    target="_blank"
    @click.prevent="shouldOpenDialog"
  >
    {{ $t('components.newsroom_admin_bar.create_story') }}
  </a>
</template>

<script>

import { mapGetters } from 'vuex'
import { goToStoryCreatePage } from '@/shared/splashScreenHelpers'

/**
 * @module CreateStoryButton
 */
export default {
  name: 'CreateStoryButton',

  computed: {
    ...mapGetters('personalNewsrooms', ['newsroomsFlat', 'firstNewsroom']),
    /**
     * Returns a link to the Stories Create page on Hypefactors
     * @returns {string}
     */
    storiesCreateUrl () {
      if (!this.firstNewsroom) return ''
      return this.$getUrl(`stories/create?brand=${this.firstNewsroom.brandId}`, 'hypefactors')
    }
  },

  methods: {
    shouldOpenDialog (event) {
      if (this.newsroomsFlat.length === 1) {
        return this.goToStoryCreatePage(this.firstNewsroom.brandId, this.firstNewsroom.id)
      }
      this.$store.commit('TOGGLE_CREATE_STORY_MODAL', true)
    },
    goToStoryCreatePage: goToStoryCreatePage
  }
}
</script>
