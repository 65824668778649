import _truncate from 'lodash/truncate'
import { stripHtml } from '@hypefactors/shared/js/utils/index.js'

function stripNewLines (input) {
  if (!input) return ''
  return input.replace(/[\r\n]+/gm, ' ')
}

/**
 * Change page title with provided new title or prepend to the current title
 * @param {string} title - The new page title
 * @param {boolean} asPrefix - Should be prepended to the current title
 */
export function changePageTitle (title, asPrefix = true) {
  let pageTitle = title
  if (asPrefix) {
    pageTitle += ' - ' + document.title
  }
  document.title = _truncate(stripNewLines(stripHtml(pageTitle)), { length: 100 })
}

/**
 * Change page description with provided text
 * Uses lodash`s truncate to trim to 155 chars
 * @param {string} desc - The new description text to add
 */
export function changePageDescription (desc) {
  let meta = document.querySelector('meta[name="description"]')

  if (meta) {
    meta.content = _truncate(stripNewLines(stripHtml(desc)), {
      'length': 155,
      'omission': ''
    })
  }
}

/**
 * Change the content value for facebook open graph meta tag
 * If the meta tag cannot be found it will create new DOM element first
 * @param {string} property - The property to look for
 * @param {string} content - The content to be set
 */
export function changeOpenGraphTag (property, content) {
  let meta = document.querySelector(`meta[property="og:${property}"]`)
  if (meta === null) {
    meta = document.createElement('meta')
    meta.setAttribute('property', 'og:' + property)
    meta.setAttribute('content', '')
    document.head.appendChild(meta)
  }
  meta.content = stripHtml(content)
}
