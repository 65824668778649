import Vue from 'vue'
import VueMultianalytics from 'vue-multianalytics'

import router from '../router'

let analyticsPlugin = {
  trackEvent: () => {
    console.log('track')
  },
  setUsername: () => {},
  reset: () => {}
}
/* Enable analytics only for none CI env */
if (process.env.VUE_APP_ENV !== 'ci') {
  const gaConfig = {
    appName: 'Hype.news', // Mandatory
    appVersion: '1', // Mandatory
    trackingId: 'UA-68682856-6' // Mandatory
  }
  const facebookConfig = {
    token: '373851799695650'
  }
  analyticsPlugin = VueMultianalytics.install(Vue, {
    modules: {
      ga: gaConfig,
      facebook: facebookConfig
      // temporary disable segement analytics to stay in startup plan
      // segment: {
      //   token: process.env.VUE_APP_SEGMENT_KEY,
      //   debug: process.env.VUE_APP_ENV !== 'production'
      // }
    },
    routing: {
      vueRouter: router
    },
    returnModule: true
  })
}

export { analyticsPlugin }
