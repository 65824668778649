import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.mixin({
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },

  methods: {
    appLoading (loading) {
      if (loading) {
        this.startLoad()
      } else {
        this.stopLoad()
      }
    },

    /**
     * Closes all the auth related modals
     */
    closeAllAuthModals () {
      this.$store.commit('closeAllAuthModals')
    },

    /**
     * Displays a fullscreen loader with a top loading bar
     */
    // TODO: Implement way to enable/disable the progress bar
    startLoad () {
      this.$Progress.start()

      this.$store.commit('START_LOAD')
    },

    /**
     * Stops the fullscreen loader
     */
    // TODO: Implement way to enable/disable the progress bar
    stopLoad () {
      this.$Progress.finish()

      this.$store.commit('STOP_LOAD')
    },

    /**
     * Stops the fullscreen loader with an error
     */
    stopLoadWithFail () {
      this.$Progress.fail()

      this.$store.commit('STOP_LOAD')
    }
  }
})
