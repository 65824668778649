import Vue from 'vue'

import Loading from 'element-ui/lib/loading'
import Message from 'element-ui/lib/message'
import MessageBox from 'element-ui/lib/message-box'
import Notification from 'element-ui/lib/notification'
import ElementLocale from 'element-ui/lib/locale/index'

import { i18n } from './i18n'

// Pagination,
import Dialog from 'element-ui/lib/dialog'
// Autocomplete,
import Dropdown from 'element-ui/lib/dropdown'
import DropdownMenu from 'element-ui/lib/dropdown-menu'
import DropdownItem from 'element-ui/lib/dropdown-item'
// Menu,
// Submenu,
// MenuItem,
// MenuItemGroup,
// Input,
// InputNumber,
import Radio from 'element-ui/lib/radio'
// RadioGroup,
// RadioButton,
// Checkbox,
import Checkbox from 'element-ui/lib/checkbox'
import CheckboxGroup from 'element-ui/lib/checkbox-group'
// CheckboxGroup,
// Switch,
import Select from 'element-ui/lib/select'
import Option from 'element-ui/lib/option'
// OptionGroup,
import Button from 'element-ui/lib/button'
// ButtonGroup,
// Table,
// TableColumn,
// DatePicker,
// TimeSelect,
// TimePicker,
import Popover from 'element-ui/lib/popover'
import Tooltip from 'element-ui/lib/tooltip'
// Breadcrumb,
// BreadcrumbItem,
// Form,
// FormItem,
// Tabs,
// TabPane,
// Tag,
// Tree,
// Alert,
// Slider,
// Icon,
// Row,
// Col,
// Upload,
// Progress,
// Spinner,
// Badge,
// Card,
// Rate,
import Steps from 'element-ui/lib/steps'
import Step from 'element-ui/lib/step'
// Carousel,
// Scrollbar,
import Scrollbar from 'element-ui/lib/scrollbar'
// CarouselItem,
// Collapse,
// CollapseItem,
// Cascader,
// ColorPicker,
import ColorPicker from 'element-ui/lib/color-picker'

// Vue.use(Pagination)
Vue.use(Dialog)
// Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
// Vue.use(Input)
// Vue.use(InputNumber)
Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
// Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
// Vue.use(OptionGroup)
Vue.use(Button)
// Vue.use(ButtonGroup)
// Vue.use(Table)
// Vue.use(TableColumn)
// Vue.use(DatePicker)
// Vue.use(TimeSelect)
// Vue.use(TimePicker)
Vue.use(Popover)
Vue.use(Tooltip)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Tag)
// Vue.use(Tree)
// Vue.use(Alert)
// Vue.use(Slider)
// Vue.use(Icon)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Upload)
// Vue.use(Progress)
// Vue.use(Spinner)
// Vue.use(Badge)
// Vue.use(Card)
// Vue.use(Rate)
Vue.use(Steps)
Vue.use(Step)
// Vue.use(Carousel)
Vue.use(Scrollbar)
// Vue.use(CarouselItem)
// Vue.use(Collapse)
// Vue.use(CollapseItem)
// Vue.use(Cascader)
Vue.use(ColorPicker)
//
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

ElementLocale.i18n((key, value) => i18n.t(key, value))
