import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

import Api from '@/services/Api'

const state = {
  initialLoadingState: true,
  appDataLoaded: false,

  apiMaintenaceModeStatus: null,
  apiMaintenaceModeMessage: null,

  countries: [],
  countriesByIso: {},
  languages: [],
  fetchingCountries: false
}

const getters = {
  initialLoadingState (state) {
    return state.initialLoadingState
  },

  /**
   * Determines if the application is on maintenance mode.
   *
   * @return {Boolean}
   */
  isOnMaintenanceMode (state) {
    return state.apiMaintenaceModeStatus === true
  },

  /**
   * Returns the maintenance mode status message, if set.
   *
   * @return {String}
   */
  getMaintenanceModeMessage (state) {
    return state.apiMaintenaceModeMessage
  },

  countries (state) {
    return state.countries
  },

  getCountryByIso2: (state) => iso2 => {
    return state.countriesByIso[iso2]
  },

  fetchingCountries (state) {
    return state.fetchingCountries
  },

  findCountryByIsoAlpha: (state) => (isoAlpha2) => {
    return state.countries.find(c => (c.iso_alpha2 === isoAlpha2))
  },

  languages (state) {
    return state.languages
  }
}

const mutations = {
  SET_INITIAL_LOADING: createSetter('initialLoadingState'),

  SET_APP_DATA_LOADED: createSetter('appDataLoaded'),

  SET_API_MAINTENANCE_MODE_STATUS: createSetter('apiMaintenaceModeStatus'),
  SET_API_MAINTENANCE_MODE_MESSAGE: createSetter('apiMaintenaceModeMessage'),

  SET_COUNTRIES (state, payload) {
    const countriesWithHashes = payload.map((country) => {
      return {
        ...country,
        compoundIndex: country.compoundIndex.data[0]
      }
    })

    state.countries = countriesWithHashes

    state.countriesByIso = countriesWithHashes.reduce((all, current) => {
      all[current.iso_alpha2] = current
      return all
    }, {})
  },

  TOGGLE_FETCHING_COUNTRIES (state, payload) {
    state.fetchingCountries = payload
  },

  SET_ALL_LANGUAGES (state, languages) {
    state.languages = languages
  }
}

const actions = {
  setMaintenanceModeStatus ({ commit }, statusCode) {
    commit('SET_API_MAINTENANCE_MODE_STATUS', statusCode)
  },

  setMaintenanceModeMessage ({ commit }, message) {
    commit('SET_API_MAINTENANCE_MODE_MESSAGE', message)
  },

  fetchCountries ({ commit, getters }, { force = false } = {}) {
    if (getters.countries.length && !force) return Promise.resolve()

    commit('TOGGLE_FETCHING_COUNTRIES', true)

    return Api.get('countries?include=currency,compoundIndex')
      .then(response => {
        commit('TOGGLE_FETCHING_COUNTRIES', false)
        commit('SET_COUNTRIES', response.data.data)
      })
  },

  fetchLanguages ({ commit, state }) {
    if (state.languages.length) return Promise.resolve()

    return Api.get('languages').then(response => {
      commit('SET_ALL_LANGUAGES', response.data.data)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
