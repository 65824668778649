import Vue from 'vue'

Vue.component('basic-select', () => import(/* webpackChunkName: "BasicSelect" */ '@hypefactors/shared/js/components/core/BasicSelect'))
Vue.component('color-picker', () => import(/* webpackChunkName: "ColorPicker" */ '@hypefactors/shared/js/components/core/ColorPicker'))
Vue.component('round-avatar', () => import(/* webpackChunkName: "RoundAvatar" */ '@hypefactors/shared/js/components/core/RoundAvatar'))
Vue.component('newsroom-card', () => import(/* webpackChunkName: "NewsroomCard" */ '@/components/core/NewsroomCard'))
Vue.component('followNewsroomButton', () => import(/* webpackChunkName: "FollowNewsroomButton" */ '@/components/core/FollowNewsroomButton'))
Vue.component('loadMore', () => import(/* webpackChunkName: "LoadMore" */ '@/components/core/LoadMore'))
Vue.component('Attachments', () => import(/* webpackChunkName: "Attachments" */ '@/components/core/Attachments'))
Vue.component('vSeparator', () => import(/* webpackChunkName: "VSeparator" */ '@hypefactors/shared/js/components/core/VSeparator'))
Vue.component('croppie-wrapper', () => import(/* webpackChunkName: "CroppieWrapper" */ '@hypefactors/shared/js/components/core/CroppieWrapper'))
Vue.component('FileUploader', () => import(/* webpackChunkName: "FileUploader" */ '@hypefactors/shared/js/components/forms/FileUploader'))
Vue.component('VButton', () => import(/* webpackChunkName: "VButton" */ '@hypefactors/shared/js/components/core/VButton'))
Vue.component('WebsiteLink', () => import(/* webpackChunkName: "WebsiteLink" */ '@hypefactors/shared/js/components/core/WebsiteLink'))
Vue.component('CountryPicker', () => import(/* webpackChunkName: "CountryPicker" */ '@hypefactors/shared/js/components/core/CountryPicker'))
Vue.component('CountryFlag', () => import(/* webpackChunkName: "CountryFlag" */ '@hypefactors/shared/js/components/core/CountryFlag'))
Vue.component('LanguagePicker', () => import(/* webpackChunkName: "LanguagePicker" */ '@hypefactors/shared/js/components/core/LanguagePicker'))
Vue.component('VIcon', () => import(/* webpackChunkName: "VIcon" */ '@hypefactors/shared/js/components/core/VIcon'))
Vue.component('FakeDataRibbon', () => import(/* webpackChunkName: "FakeDataRibbon" */ '@hypefactors/shared/js/components/core/FakeDataRibbon'))
Vue.component('Heading', () => import(/* webpackChunkName: "Heading" */ '@hypefactors/shared/js/components/core/Heading'))
Vue.component('VImage', () => import(/* webpackChunkName: "VImage" */ '@hypefactors/shared/js/components/core/VImage'))
