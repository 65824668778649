<template>
  <div class="TheNavigationWrap" data-testid="TheNavigation">
    <div
      v-click-away="closeMenu"
      class="container no-margin:mobile"
    >
      <div class="TheNavigation">
        <div
          :class="{ isActive: isMenuActive }"
          class="TheNavigation__Menu"
        >
          <div class="NavItem">
            <nav-dashboard-button />
          </div>
          <div class="NavItem">
            <router-link
              :to="$i18nRoute({ name: 'home' })"
              class="NavLink"
              exact-active-class="NavLink--isCurrent"
            >
              {{ $t('components.top_nav.read_news') }}
            </router-link>
          </div>
          <template v-if="!isLoggedIn">
            <div class="NavItem">
              <a :href="forBrandsUrl" class="NavLink">
                {{ $t('components.top_nav.for_brands') }}
              </a>
            </div>
            <div class="NavItem">
              <a :href="forJournalistsUrl" class="NavLink">
                {{ $t('components.top_nav.for_journalists') }}
              </a>
            </div>
          </template>
          <div class="NavItem">
            <nav-auth-button />
          </div>
          <div
            v-if="isLoggedIn"
            class="NavItem"
          >
            <create-story-button class="NavLink" />
          </div>
          <div class="NavSeparator" />
          <div class="NavItem NavItem--LangSwitcher">
            <!-- Language Switcher -->
            <the-language-switcher />
          </div>
        </div>
        <div class="TheNavigation__RightBand">
          <div class="NavLogoHolder">
            <router-link
              :to="$i18nRoute({name: 'home'})"
              class="NavLink p-r-none"
              data-testid="logoAnchor"
            >
              <img
                :src="$asset('/images/branding/hypenews/logo/logo_white.svg')"
                class="LogoImage"
              >
            </router-link>
          </div>
          <div class="NavBurger">
            <a class="NavLink" @click="toggleMenu">
              <i
                :class="isMenuActive ? 'hf-x': 'hf-menu'"
                class="hf hf-2x"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateStoryButton from './core/CreateStoryButton'
import NavAuthButton from './navigation/NavAuthButton'
import TheLanguageSwitcher from '@hypefactors/shared/js/components/core/TheLanguageSwitcher'
import NavDashboardButton from './navigation/NavDashboardButton/Index'

/**
 * The main navigation element
 * @module TheNavigation
 */
export default {
  components: {
    CreateStoryButton,
    NavAuthButton,
    NavDashboardButton,
    TheLanguageSwitcher
  },

  data () {
    return {
      isMenuActive: true
    }
  },

  computed: {
    forBrandsUrl () {
      // the company website uses all lower case
      const locale = this.$TranslationService.currentLanguage.toLocaleLowerCase()
      const url = locale === 'en' ? 'for-brands' : `for-brands-${locale}`
      return this.$getUrl(url, 'company', locale)
    },
    forJournalistsUrl () {
      const locale = this.$TranslationService.currentLanguage.toLocaleLowerCase()
      const url = locale === 'en' ? 'for-journalists' : `for-journalists-${locale}`
      return this.$getUrl(url, 'company', locale)
    }
  },

  watch: {
    '$route.name': 'closeMenu'
  },

  mounted () {
    // TODO: Very legacy. Needs to be moved away to a separate modal component
    const cb = (e) => {
      if (e.keyCode === 27) {
        this.closeAllAuthModals()
      }
    }
    document.addEventListener('keydown', cb)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', cb)
    })
  },

  methods: {
    closeMenu (el) {
      this.isMenuActive = false
    },

    toggleMenu () {
      this.isMenuActive = !this.isMenuActive
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

$NavSpaceBase: .4rem;
$NavLink__Padding: .4rem .8rem;
$NavLink__PaddingLarge: .8rem;

.TheNavigationWrap {
  z-index: 20;
  position: relative;
  background: $navbar-background-color;
}

.TheNavigation {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: flex-start;

  &__Menu {
    width: 100%;
    flex: 1 1 auto;
    position: absolute;
    top: 100%;
    background: $navbar-background-color;
    color: $hf__color-white;
    display: none;
    text-align: center;
    box-shadow: 0 6px 6px transparentize($grey, .5);

    &.isActive {
      display: block;
    }
  }

  &__RightBand {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .NavLogoHolder {
      .LogoImage {
        height: 40px;
        display: block;
      }
    }

    .NavBurger {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  /* Global level styling */
  .NavLink {
    padding: $NavLink__Padding;
    display: block;
    color: $hf__color-white;

    &--large {
      padding: $NavLink__PaddingLarge;
    }

    &--isCurrent {
      color: $hf__color-primary--alternative;
    }
  }

  @include mobile {
    .NavLink {
      &--hasNavButton {
        padding: 0;
      }
    }

    .NavButton {
      width: 100%;
      border-radius: 0;
      font-size: 1rem;
      font-weight: normal;
      padding-top: $NavLink__PaddingLarge;
      padding-bottom: $NavLink__PaddingLarge;
      height: auto;
    }
  }

  .NavSeparator {
    border-bottom: 1px solid $grey-lighter;
    margin: $NavSpaceBase 0;
  }
}

@include tablet() {
  .TheNavigation {
    &__Menu {
      position: relative;
      display: flex !important;
      box-shadow: none;
      align-items: center;
      width: auto;
    }

    .NavLink {
      padding: $NavLink__PaddingLarge;
    }

    .NavSeparator {
      display: none;
    }
  }

  .TheNavigation__RightBand {
    margin-left: auto;
    width: auto;

    .NavLogoHolder {
      .NavLink {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .LogoImage {
        height: 70px;
      }
    }

    .NavBurger {
      display: none;
    }
  }
}
</style>
