import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

let fetchingHandlesPromise = null

const state = {
  handles: []
}

const getters = {}

const mutations = {
  STORE_HANDLES: createSetter('handles')
}

const actions = {
  async fetchSocialHandles ({ commit }, newsroomId) {
    if (fetchingHandlesPromise) return
    fetchingHandlesPromise = NewsroomService.fetchSocialHandles(newsroomId)
    const handles = await fetchingHandlesPromise
    fetchingHandlesPromise = null
    commit('STORE_HANDLES', handles)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
