<template>
  <free-newsroom-button v-if="!isLoggedIn" />
  <newsrooms-dropdown v-else-if="$hasAccess('company')" />
  <journalist-dashboard-link v-else-if="$hasAccess('reader')" />
</template>

<script>
import FreeNewsroomButton from './FreeNewsroomButton'
import JournalistDashboardLink from './JournalistDashboardLink'
import NewsroomsDropdown from './NewsroomsDropdown'

/**
 * @module TheNavigationDashboardButton
 */
export default {
  name: 'TheNavigationDashboardButton',
  components: { JournalistDashboardLink, NewsroomsDropdown, FreeNewsroomButton }
}
</script>
