import Api from '@/services/Api'

import _omit from 'lodash/omit'
import _merge from 'lodash/merge'

const state = {
  modals: {
    settings: false,
    interests: false,
    bio: false,
    socials: false
  },
  user: {
    first_name: '',
    last_name: '',
    email: '',
    timezone: '',
    // metadata
    metadata: {
      address: '',
      phone_number: '',
      mobile_number: '',
      bio: '',
      website: '',
      company_name: '',
      occupation: '',
      facebook_profile: '',
      twitter_profile: '',
      linkedin_profile: '',
      instagram_profile: '',
      pinterest_profile: ''
    }
  },
  occupation_types: [
    'journalist', 'blogger', 'freelancer', 'other'
  ]
}

const getters = {
  dashboardSettingsModalVisible (state) {
    return state.modals.settings
  },
  dashboardInterestsModalVisible (state) {
    return state.modals.interests
  },
  dashboardBioModalVisible (state) {
    return state.modals.bio
  },
  dashboardSocialsModalVisible (state) {
    return state.modals.socials
  },
  dashboardUser (state) {
    return state.user
  },
  dashboardUserHasInterests (state, getters) {
    return !!getters.dashboardUser.interests && getters.dashboardUser.interests.length > 0
  },
  dashboardOccupationTypes (state) {
    return state.occupation_types
  }

}

const mutations = {
  TOGGLE_DASHBOARD_MODAL (state, { value, modal }) {
    value = value || !state.modals[modal]
    state.modals[modal] = value
  },
  CLOSE_ALL_DASHBOARD_MODALS (state) {
    state.modals = Object.keys(state.modals).map(key => ({ [key]: false }))
  },
  SET_DASHBOARD_USER (state, user) {
    state.user = {
      ...user,
      interests: user.interests.data,
      followed_newsrooms: user.followed_newsrooms.data,
      country: user.country.data
    }
  }
}

const actions = {
  toggleDashboardSettingsModal ({ commit }, value) {
    commit('TOGGLE_DASHBOARD_MODAL', { value, modal: 'settings' })
  },
  toggleDashboardInterestsModal ({ state, commit }, value) {
    commit('TOGGLE_DASHBOARD_MODAL', { value, modal: 'interests' })
  },
  toggleDashboardBioModal ({ commit }, value) {
    commit('TOGGLE_DASHBOARD_MODAL', { value, modal: 'bio' })
  },
  toggleDashboardSocialsModal ({ commit }, value) {
    commit('TOGGLE_DASHBOARD_MODAL', { value, modal: 'socials' })
  },
  updateDashboardUserRequest ({ getters, commit }, values) {
    const omittedProperties = ['country', 'interests', 'followed_newsrooms', 'avatar']
    const omittedUserProperties = _omit(getters.dashboardUser, omittedProperties)
    const mergedUserPayload = _merge(omittedUserProperties, values)
    let payload = {
      ...mergedUserPayload,
      // TODO: Revisit this because it gets set as 0
      timezone: values.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone // set the timezone if we dont supply it
    }
    return Api.put('user?include=country,followed_newsrooms,interests', payload)
      .then(response => {
        commit('SET_DASHBOARD_USER', response.data.data)
        return response
      })
  },
  fetchDashboardUser ({ commit }) {
    return Api.get('user?include=country,followed_newsrooms,interests')
      .then(response => {
        commit('SET_DASHBOARD_USER', response.data.data)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
