import store from '@/store/index'

/**
 * Check if the user's type matches the provided
 * @typedef hasAccess
 * @param {Array|String} role
 * @param {Object} [user]
 * @return {boolean}
 */
export function hasAccess (role, user = store.getters.currentUser) {
  const accType = user.type
  return Array.isArray(role) ? role.includes(accType) : role === accType
}
