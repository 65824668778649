<template>
  <div class="NavLink NavLink--hasNavButton">
    <router-link
      :to="$i18nRoute({ name: 'NewsroomRegister' })"
      class="FreeNewsroomButton NavButton button is-normal is-white"
    >
      {{ $t('components.top_nav.free_newsroom') }}
    </router-link>
  </div>
</template>

<script>
/**
 * @module FreeNewsroomButton
 */
export default {
  name: 'FreeNewsroomButton'
}
</script>
