import Vue from 'vue'
import Vuex from 'vuex'

import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

import createPersistedState from 'vuex-persistedstate'

import modules from './modules'
import { localStorageService } from '@hypefactors/shared/js/services/LocalStorageService'

Vue.use(Vuex)

const persistedInstance = window.Cypress ? undefined : createPersistedState({
  storage: localStorageService,
  paths: [
    'auth.user'
  ]
})

export default new Vuex.Store({
  state: {
    appLoading: false,
    followNewsroomNeedsLoginModal: false,
    createStoryModalVisible: false
  },

  getters: {
    isAppLoading (state) {
      return !!state.appLoading
    }
  },

  mutations: {
    START_LOAD: createSetter('appLoading', true),

    STOP_LOAD: createSetter('appLoading', false),

    closeAllAuthModals (state) {
      state.followNewsroomNeedsLoginModal = false
      document.querySelector('body').classList.remove('modal-opened')
    },

    TOGGLE_FOLLOW_NEWSROOM_MODAL: createSetter('followNewsroomNeedsLoginModal'),

    TOGGLE_CREATE_STORY_MODAL: createSetter('createStoryModalVisible')
  },

  modules,

  plugins: [persistedInstance].filter(Boolean)
})
