import router from '@/router'
import store from '@/store'
import { load } from '@hypefactors/shared/js/utils'
import { Trans } from '@/services/TranslationService'
import Api from '@/services/Api'

/**
 * Axios request Interceptor
 * Sets the Auth token on each request.
 * @param config
 * @returns {*}
 */
function attachAccessToken (config) {
  const accessToken = load('access_token', '')

  if (accessToken) {
    config.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  return config
}

/**
 * Axios Response Interceptor.
 * Redirects a user to the home page and logs them out of they receive a 401 error.
 * @param {AxiosError} err
 * @returns {Promise<never>}
 */
async function unauthorizedInterceptor (err) {
  if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    // Do something with response error
    const lang = Trans.currentLanguage
    await store.dispatch('logoutGlobally')
    router.push({ name: 'login', params: { lang } })
  }
  return Promise.reject(err)
}

/**
 * Add the authorization header to each request
 */
Api.service.interceptors.request.use(attachAccessToken)

/**
 * Logout the user if we get a 401
 */
Api.service.interceptors.response.use(response => response, unauthorizedInterceptor)
