import Vue from 'vue'
import routes from './routes'
import VueRouter from 'vue-router'
import { onError } from '@hypefactors/shared/js/utils/routerUtilities'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
  /* 15.3.2018 - DOBRI: I REMOVED THIS BECAUSE IT WAS BUGGING EDGE BROWSER - adds a /undefined to url */
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   }
  //
  //   return { x: 0, y: 0 }
  // }
})

router.onError(onError)

export default router
