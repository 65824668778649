<template>
  <div>
    <nav-dropdown
      ref="navDropdown"
      :items="brands"
      class="NewsroomsDropdown"
      has-arrow
      min-width="250px"
    >
      <a
        slot="trigger"
        slot-scope="{ toggle }"
        class="p-l-none NavLink NavLink--large"
        @click="toggle"
      >
        {{ $t('components.newsrooms_dropdown.trigger_text') }}
      </a>
      <div
        slot-scope="{ item: brand }"
        class="is-flex is-aligned-middle"
      >
        <span class="m-r-a">{{ brand.name }}</span>
        <router-link
          v-for="newsroom in brand.newsrooms"
          :key="newsroom.id"
          :to="{ name: 'newsroom.edit', params: { newsroomSlug: newsroom.slug } }"
          class="has-text-transform-none"
          active-class="has-text-primary"
        >
          <country-flag :country="newsroom.country.iso_alpha2" />
        </router-link>
      </div>
    </nav-dropdown>
    <div class="NavSeparator" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavDropdown from '@hypefactors/shared/js/components/core/NavDropdown'

/**
 * @module NewsroomsDropdown
 */
export default {
  name: 'NewsroomsDropdown',
  components: { NavDropdown },

  computed: {
    ...mapGetters(['brands'])
  },

  watch: {
    '$route.fullPath': function () {
      this.$refs.navDropdown.closeMenu()
    }
  }
}
</script>
