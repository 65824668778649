const state = {
  isEmbedded: false,

  newsroomSettings: {
    newsroomHeading: false,
    aboutSection: false,
    assetsSection: false
  },

  storySettings: {
    storyImage: false,
    contactCards: false,
    attachments: false,
    storyTypeTag: false,
    dateStamp: false,
    // just hides the back button, not used for embedding
    hideBackButton: false
  }
}

const getters = {
  isEmbedded (state) {
    return state.isEmbedded
  },

  newsroomSettings (state) {
    return state.newsroomSettings
  },

  storySettings (state) {
    return state.storySettings
  }
}

const mutations = {
  SET_EMBEDDED (state, isEmbedded) {
    state.isEmbedded = isEmbedded
  },

  SET_NEWSROOM_SETTINGS (state, selectedSettings) {
    for (let key in state.newsroomSettings) {
      state.newsroomSettings[key] = selectedSettings.includes(key)
    }
  },

  SET_STORY_SETTINGS (state, selectedSettings) {
    for (let key in state.storySettings) {
      state.storySettings[key] = selectedSettings.includes(key)
    }
  }
}

export default {
  state,
  getters,
  mutations
}
