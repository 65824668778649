import router from '@/router'
import _safeGet from 'lodash/get'
import Store from '@/store/index'
import { Trans } from '@/services/TranslationService'
import { i18n } from '@/bootstrap/i18n'
import { changeOpenGraphTag, changePageDescription, changePageTitle, persist, load } from '@/shared/utils'
import { hasAccess } from '../shared/auth'
import { addActiveCampaignTracking, removeActiveCampaingTracking } from '@hypefactors/shared/js/utils/trackers'
import { getUrlToDomain } from '@hypefactors/shared/js/utils'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'
import { maintenanceMode } from '@hypefactors/shared/js/router/guards'

function getSEOContent (value, isI18n = true) {
  if (isI18n) return value ? i18n.t(value) : ''
  return value
}

router.beforeEach(maintenanceMode)

/* Deploy */
router.beforeEach((to, from, next) => {
  const forceReload = load('FORCE_RELOAD', false)
  if (!forceReload) return next()
  persist('FORCE_RELOAD', false)
  LocationService.assign(to.fullPath)
})

/* i18n */
/**
 * Check if current language is supported
 */
router.beforeEach((to, from, next) => {
  try {
    const lang = _safeGet(to, 'params.lang', Trans.currentLanguage)
    // Check if user accesses supported language (company and stories page do not have URL param so rely on Vuex cached lang)
    if (Trans.isLangSupported(lang)) {
      return Trans.changeLanguage(lang).then(() => next())
    }
    const userPreferredLanguage = Trans.getUserSupportedLang()

    const toRoute = router.resolve({ ...to, params: { lang: userPreferredLanguage } })
    return next(toRoute.location)
  } catch (err) {
    return next('/en')
  }
})

// scrollbehavior is not working. its function body is never invoked
// this work around solves the issue
router.afterEach((to) => {
  if (to.matched.some(m => m.meta.scrollToTop)) {
    window.scrollTo(0, 0)
  }
})

router.afterEach((to, from) => {
  removeActiveCampaingTracking()
  addActiveCampaignTracking(
    Store.getters.currentUser && Store.getters.currentUser.email,
    getUrlToDomain(from.fullPath, 'hypenews', false),
    getUrlToDomain(to.fullPath, 'hypenews', false)
  )
})

/* SEO */
/**
 * Handle if the route has SEO specific meta properties
 */
router.afterEach((to, from) => {
  const siteName = 'Hype.News'

  if (typeof to.meta.seo !== 'undefined') {
    const seo = to.meta.seo
    const isI18n = seo.i18n
    // const { title = '', section = '', desc = '' } = seo
    const title = getSEOContent(seo.title, isI18n)
    const section = getSEOContent(seo.section, isI18n)
    const desc = getSEOContent(seo.desc, isI18n)
    const sep1 = title.length ? ' - ' : ''
    const sep2 = section.length ? ' | ' : ''
    const pageTitle = `${title}${sep1}${section}${sep2}${siteName}`
    // set page title
    changePageTitle(pageTitle || siteName, false)
    // set page description
    changePageDescription(desc)

    changeOpenGraphTag('title', pageTitle || siteName)
    changeOpenGraphTag('type', 'website')
    changeOpenGraphTag('url', process.env.HYPENEWS_URL + to.fullPath)
    // @TODO set a generic placeholder image for Hype.News
    changeOpenGraphTag('image', '')
    changeOpenGraphTag('site_name', siteName)
    changeOpenGraphTag('description', desc)
  }
})

/* Auth */
router.beforeEach((to, from, next) => {
  if (Store.state.app.isOnMaintenanceMode) {
    return
  }

  if (!to.matched.some(record => record.meta.hasOwnProperty('auth'))) return next()
  const authRoutes = to.matched.slice().reverse().filter(route => route.meta.hasOwnProperty('auth'))
  const isLoggedIn = Store.getters.isLoggedIn
  const redirectIfNoPermission = {
    name: 'home',
    params: {
      lang: Trans.currentLanguage
    },
    query: {
      redirect: to.path
    }
  }
  let authPath

  if (authRoutes.length) {
    // get last item's auth property
    authPath = authRoutes[0].meta.auth
  }
  // If user is logged he cannot enter
  if (authPath === false) {
    return !isLoggedIn ? next()
      : from.path !== '/' ? next(false)
        : next({ ...redirectIfNoPermission, query: null })
  }
  // If user is not logged, and auth is not false, then he cant enter
  if ((authPath === true && isLoggedIn === true) || hasAccess(authPath)) return next()
  return next({ ...redirectIfNoPermission, name: 'login' })
})
