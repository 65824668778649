<template>
  <div
    :class="[`page-${routeName}`]"
    class="hypedesk"
  >
    <maintenance-mode v-if="isOnMaintenanceMode" />
    <div
      v-else
      v-loading="isAppLoading"
      class="app-content-wrapper"
    >
      <the-navigation v-if="shouldShowNavAndFooter" />

      <div class="main-content">
        <transition name="fade">
          <router-view class="view" />
        </transition>
      </div>

      <template v-if="shouldShowNavAndFooter">
        <the-footer v-if="routeName !== 'story' && !routeName.includes('newsroom-')" />
        <the-footer-thin v-else />
      </template>
    </div>
    <vue-progress-bar />
    <followCompanyNeedsLogin v-if="$store.state.followNewsroomNeedsLoginModal" />
    <create-story-dialog />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { persist } from '@hypefactors/shared/js/utils/index.js'
import MaintenanceMode from '@hypefactors/shared/js/components/Maintenance'

import CreateStoryDialog from '../components/core/CreateStoryDialog'
import TheNavigation from '../components/TheNavigation'

const followCompanyNeedsLogin = () => import(/* webpackChunkName: "followCompanyNeedsLogin" */ '@/components/modals/followCompanyNeedsLogin.vue')
const TheFooter = () => import(/* webpackChunkName: "TheFooter" */ '@/components/TheFooter.vue')
const TheFooterThin = () => import(/* webpackChunkName: "TheFooterThin" */ '@/components/TheFooterThin.vue')

export default {
  components: {
    MaintenanceMode,
    CreateStoryDialog,
    TheNavigation,
    TheFooter,
    TheFooterThin,

    // Various modals
    followCompanyNeedsLogin
  },

  computed: {
    ...mapGetters([
      'isAppLoading',
      'isEmbedded',
      'isOnMaintenanceMode'
    ]),

    routeName () {
      const routeName = this.$route.name || ''
      return routeName.replace(/\./g, '-')
    },

    shouldShowNavAndFooter () {
      return !this.isEmbedded && this.$route.name !== 'pr_story'
    }
  },

  async created () {
    // TODO: Hack to have unit tests working.. Figure out a better solution later...
    if (process.env.VUE_APP_ENV !== 'test' && process.env.VUE_APP_ENV !== 'ci') {
      await this.$apiStatusChecker.start()
    }

    if (window.Cypress) {
      document.body.classList.add('disableAnimations')
      document.body.classList.add('CypressTest')
    }

    this.setupEmbeddedPage()
  },

  mounted () {
    this.subscribeToPusherDeploymentEvent()
  },

  methods: {
    ...mapMutations({
      setEmbedded: 'SET_EMBEDDED'
    }),

    subscribeToPusherDeploymentEvent () {
      const channel = this.$pusher.subscribe('deployments')
      channel.bind('hypenews', data => {
        persist('FORCE_RELOAD', true)
      })
    },

    setupEmbeddedPage () {
      const isEmbedded = window.location.pathname.startsWith('/embedded')

      if (isEmbedded && !window.Cypress) {
        import('iframe-resizer/js/iframeResizer.contentWindow')
      }

      this.setEmbedded(isEmbedded)

      if (isEmbedded) {
        document.documentElement.classList.add('is-app-embedded')
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/app.scss";

.is-app-embedded {
  background-color: transparent;

  body {
    height: auto;
  }

  iframe#launcher {
    display: none;
  }
}
</style>
