<template>
  <router-link
    v-if="!isLoggedIn"
    :to="$i18nRoute({ name:'login' })"
    data-testid="SignInButton"
    class="NavLink NavAuthButton"
  >
    {{ $t('components.top_nav.sign_in') }}
  </router-link>
  <a
    v-else
    class="NavLink NavAuthButton"
    data-testid="SignOutButton"
    @click="logout"
  >
    {{ $t('components.top_nav.sign_out') }}
  </a>
</template>
<script>
import { mapActions } from 'vuex'
import { asyncTimeout } from '@hypefactors/shared/js/utils'

/**
 * @module NavAuthButton
 */
export default {
  name: 'NavAuthButton',
  methods: {
    ...mapActions(['logoutGlobally']),

    async logout () {
      const to = this.$i18nRoute({ name: 'home' })
      const { href } = this.$router.resolve(to)
      this.$notify.success(this.$t('success.logged_out'))
      await asyncTimeout(1500)
      this.logoutGlobally(href)
    }
  }
}
</script>
