import * as CategoryApiService from '@hypefactors/shared/js/services/api/CategoryService'
import CategoryService from '@hypefactors/shared/js/services/CategoryService'

const state = {
  fetchingStories: false,
  fetchingCategories: false,
  categories: [],
  selectedCategories: {
    0: null,
    1: null,
    2: null
  },
  filters: {
    language: '',
    country: '',
    state: '',
    date: ''
  },
  story: {},
  stories: []
}

const getters = {
  getActiveFilters (state, getters) {
    return {
      main_category: getters.mainSelectedCategory,
      sub_category: getters.subSelectedCategory,
      spec_category: getters.specSelectedCategory
    }
  },
  getStory (state) {
    return state.story
  },
  isFetchingStories (state) {
    return state.fetchingStories
  },
  fetchingCategories (state) {
    return state.fetchingCategories
  },
  allStoryCategories (state) {
    return state.categories
  },
  getChildrenOfCategory: state => parentCategoryId => {
    return state.categories.filter(category => category.parent_id === parentCategoryId)
  },
  mainCategories (state, getters) {
    return getters.getChildrenOfCategory(0)
  },
  subCategories (state, getters) {
    return state.selectedCategories[0] ? getters.getChildrenOfCategory(state.selectedCategories[0].id) : []
  },
  specCategories (state, getters) {
    return state.selectedCategories[1] ? getters.getChildrenOfCategory(state.selectedCategories[1].id) : []
  },
  specCategoriesFiltered: (state, getters) => (filterAgainst = []) => {
    return getters.specCategories.filter(cat => !filterAgainst.includes(cat))
  },
  mainSelectedCategory (state) {
    return state.selectedCategories[0]
  },
  subSelectedCategory (state) {
    return state.selectedCategories[1]
  },
  specSelectedCategory (state) {
    return state.selectedCategories[2]
  },
  selectedStoryCategories (state) {
    return state.selectedCategories
  },
  storiesList (state) {
    return state.stories
  }
}

const mutations = {
  TOGGLE_FETCHING_STORIES (state, isFetching = !state.fetchingStories) {
    state.fetchingStories = isFetching
  },
  TOGGLE_FETCHING_CATEGORIES (state, isFetching = !state.fetchingCategories) {
    state.fetchingCategories = isFetching
  },
  SET_CATEGORIES (state, categories) {
    state.categories = categories
  },
  SELECT_CATEGORY (state, { category, level }) {
    const depth = (category && typeof category.depth !== 'undefined') ? category.depth : level
    state.selectedCategories[depth] = category
  },
  SELECT_CATEGORY_BY_ID (state, { category, level }) {
    const depth = (category && typeof category.depth !== 'undefined') ? category.depth : level
    state.selectedCategories[depth] = state.categories.find(cat => cat.id === Number(category))
  },
  CLEAR_SELECTED_CATEGORY (state, level) {
    state.selectedCategories[level] = null
  },
  SET_STORY (state, story) {
    state.story = story
  },
  SET_STORIES (state, stories) {
    state.stories = stories
  },
  APPEND_STORIES (state, stories) {
    state.stories = [...state.stories, ...stories]
  }
}

const actions = {
  shouldFetchCategories ({ getters, dispatch }) {
    return getters.allStoryCategories.length > 0 ? Promise.resolve() : dispatch('fetchCategories')
  },
  fetchCategories ({ commit }) {
    commit('TOGGLE_FETCHING_CATEGORIES', true)
    return CategoryApiService.getAllFlat()
      .then(response => {
        commit('TOGGLE_FETCHING_CATEGORIES', false)
        commit('SET_CATEGORIES', response)
        CategoryService._setFlatCategories(response)
      })
      .catch(error => {
        commit('TOGGLE_FETCHING_CATEGORIES', false)
        throw error
      })
  },
  clearSelectedCategoriesFromThisLevelUp ({ state, commit }, level = 0) {
    const numberOfSelectedCategories = Object.keys(state.selectedCategories).length
    let i = level
    for (i; i < numberOfSelectedCategories; i++) {
      commit('CLEAR_SELECTED_CATEGORY', i)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
