import Api from '@/services/Api'

import { Brand } from '@hypefactors/shared/js/models/Brand'
import { getUrlToDomain } from '@hypefactors/shared/js/utils'
import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

import { analyticsPlugin } from '@/bootstrap/analytics'
import { CrossAuth } from '@/services/CrossStorage'
import { forget, load, persist } from '@/shared/utils'

let fetchBrandsPromise = null

const state = () => ({
  user: '',
  access_token: load('access_token'),
  refresh_token: load('refresh_token'),
  brands: []
})

const getters = {
  isLoggedIn (state) {
    return !!state.access_token
  },
  currentUser (state) {
    return state.user
  },
  accessToken (state) {
    return state.access_token
  },
  brands: state => state.brands
}

const mutations = {
  LOGOUT (currentState) {
    Object.assign(currentState, state())
  },
  SET_USER: createSetter('user'),
  SET_ACCESS_TOKEN: createSetter('access_token'),
  SET_REFRESH_TOKEN: createSetter('refresh_token'),
  SET_BRANDS: createSetter('brands')
}

const actions = {
  loginUserRequest ({ dispatch }, payload) {
    return Api.post('login', payload)
      .then(async response => {
        const payload = response.data.data
        return dispatch('loginProcess', payload)
      })
  },

  /**
   * Calls all necessary steps to login a user
   * @param {ActionContext} context
   * @param payload
   * @return {Promise<*>}
   */
  async loginProcess ({ dispatch }, payload) {
    await dispatch('syncUpGlobalAuth', {
      accessToken: payload.access_token,
      refreshToken: '' // we dont have it at this endpoint
    })

    return dispatch('fetchCurrentUser')
  },

  storeAuthToken ({ commit }, payload) {
    persist('access_token', payload.accessToken)
    persist('refresh_token', '') // we dont have it at this endpoint
    commit('SET_ACCESS_TOKEN', payload.accessToken)
    commit('SET_REFRESH_TOKEN', '') // we dont have it at this endpoint
  },

  logOutUser ({ commit }, reload = false) {
    forget('access_token')
    forget('refresh_token')
    commit('LOGOUT')
    commit('SET_ACCESS_TOKEN', '')
    commit('SET_REFRESH_TOKEN', '')

    // TODO: The redirect or reload should not happen here and not this way...
    if (typeof reload === 'string') {
      LocationService.assign(reload)
    } else if (reload === true) {
      LocationService.reload()
    }
  },

  async fetchCurrentUser ({ getters, commit, dispatch }) {
    await dispatch('fetchCurrentUserRequest')

    if (getters.currentUser.type === 'reader') {
      return
    }

    return dispatch('fetchUserBrands')
  },

  fetchCurrentUserRequest ({ commit }) {
    return Api.get('user', {
      params: {
        include: 'followed_newsrooms'
      }
    }).then((response) => {
      const user = response.data.data
      // If user is not onboarded, redirect
      if (!user.onboarded_at) {
        LocationService.assign(getUrlToDomain(`/onboarding/${user.id}`, 'hypefactors'))
        return
      }
      commit('SET_USER', user)
    })
  },

  async fetchUserBrands ({ commit, dispatch }) {
    /* If there is a request already in motion, use that promise */
    if (fetchBrandsPromise) {
      return fetchBrandsPromise
    }

    fetchBrandsPromise = Api.getData('user/brands', {
      params: {
        include: 'newsrooms.country'
      }
    })

    const response = await fetchBrandsPromise

    // Do an extra check, as sometimes the response gets busted on proxies
    if (Array.isArray(response) && response.length) {
      const brands = response.map(brand => new Brand(brand))
      await dispatch('personalNewsrooms/storeNewsroomsFromBrands', brands)
      commit('SET_BRANDS', brands)
    }

    fetchBrandsPromise = null
  },

  async syncDownGlobalAuth ({ dispatch }) {
    try {
      const tokens = await CrossAuth.getAuthTokens()

      if (tokens) {
        return dispatch('storeAuthToken', tokens)
      } else {
        return dispatch('logOutUser')
      }
    } catch (e) { }
  },

  async syncUpGlobalAuth ({ dispatch }, tokens) {
    await CrossAuth.setAuthTokens(tokens).catch((error) => {
      // continue, no matter that crossAuth did not succeed
      console.log(error)
    })

    return dispatch('storeAuthToken', tokens)
  },

  async logoutGlobally ({ dispatch }, redirect) {
    await CrossAuth.delAuthTokens().catch((error) => {
      // continue, no matter that crossAuth did not succeed
      console.log(error)
    })

    analyticsPlugin.reset()

    return dispatch('logOutUser', redirect)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
